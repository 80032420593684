<template>
  <el-main>
    <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <BreadCrumb />
      </el-col>
      <el-col :span="7">
        <el-button @click="$router.push('/work/app')"><i class="el-icon-back"></i> Back</el-button>
        <el-button :disabled="!publicUrl" type="success" @click="submitForm('form')"><i class="el-icon-check" /> Save</el-button>
      </el-col>
    </el-row>
     <el-divider></el-divider>
    <el-row style="margin-top: 10px">
      <el-form label-suffix=":"  class="app-form" :model="form" :rules="rules" ref="form" label-width="150px">
        <el-form-item label="Version" prop="version" required>
          <el-input v-model="form.version"></el-input>
        </el-form-item>
        <el-form-item label="Released Date" prop="releasedAt">
          <el-date-picker type="date" placeholder="Choose Date" v-model="form.releasedAt"></el-date-picker>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input type="textarea" v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="Customer List" prop="customers">
          <el-select
            v-model="form.customers"
            multiple>
            <el-option
              v-for="item in customers"
              :key="item._id"
              :label="item.name"
              :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Active">
          <el-switch v-model="form.active"></el-switch>
        </el-form-item>
        <el-form-item label="Zip File">
          <el-upload
            ref="upload"
            :action="signUrl"
            :multiple="false"
            :auto-upload="false"
            :show-file-list="false"
            :file-list="fileList"
            :http-request="handleFileRequest"
            :on-success="uploadSuccess"
            :on-error="uploadError"
            :before-upload="beforeUpload"
            :on-change="hanldeFileChange">
            <el-button 
              :disabled="uploading || (!fileList[0] || fileList[0].status !== 'ready')" 
              style="margin-left: 10px;" 
              type="success" 
              @click="submitUpload">Upload</el-button>
            <el-button :disabled="uploading" style="margin-left: 10px;" slot="trigger" type="primary">Browse</el-button>
            <div class="app-upload-input">
              <el-input class="app-upload-input" disabled :value="fileList[0] && fileList[0].name"></el-input>
              <el-progress :status="status" :percentage="percent"></el-progress>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="File Link" prop="lastActivity">
          <div><a :href="publicUrl">{{publicUrl}}</a></div>
        </el-form-item>
      </el-form>
    </el-row>
  </el-main>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb.vue'
import { mapState } from 'vuex'
import s3utils from '../../utils/s3.js'
import moment from 'moment'
import SparkMD5 from 'spark-md5'
import axios from 'axios'
export default {
  components: { BreadCrumb },
  data () {
    const query = this.$route.query
    return {
      form: {
        version: '',
        releasedAt: '',
        description: '',
        active: false,
        customers: []
      },
      uploading: false,
      customers: [],
      percent: 0,
      status: null,
      signUrl: '',
      fileName: '',
      md5: '',
      publicUrl: '',
      fileList: [],
      rules: {
        version: [
          { required: true, trigger: 'blur' }
        ],
        releasedAt: [
          { required: true, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: async function () {
    await this.getAllCustomer()
    this.getObject()
  },
  methods: {
    submitUpload() {
      this.uploading = true
      this.percent = 0
      this.status = null
      this.publicUrl = ''
      this.$refs.upload.submit();
    },
    uploadSuccess (response, option) {
      this.uploading = false
      let file = option.raw
      this.publicUrl = file.publicUrl
      this.status = 'success'
      this.getHead(this.publicUrl)
    },
    uploadError (err, file) {
      this.uploading = false
      this.status = 'exception'
      this.percent = 0
      file.status = 'ready'
      this.fileList = [file]
    },
    onProgress (e) {
      if (e.percent) {
        this.percent = Number(e.percent.toFixed(0))
      }
    },
    hanldeFileChange(file, fileList) {
      this.fileList = [file]
    },
    async beforeUpload (file) {
      let result = null
      try { 
        result = await this.$store.httpDispatch({})('postAWSSignurl', {
          fileName: file.uid,
          fileType: file.type
        })
      } catch (err) {
        console.log(err)
        this.uploading = false
        this.status = 'exception'
        this.percent = 0
        this.$notify.error({ title: 'Error', message: `[${err.data.code}] ${err.data.msg}`, duration: 5000 })
      }
      const { code, data } = result.data
      if (code === 0) {
        this.signUrl = data.signUrl
        this.fileName = data.fileName
        file.fileName = data.fileName
        file.signUrl = data.signUrl
        file.publicUrl = data.publicUrl
        file.fields = data.fields
      } else {
        this.uploading = false
        this.status = 'exception'
        this.percent = 0
        this.$notify.error({ title: 'Error', message: 'upload failed', duration: 5000 })
        return false
      }
      return true
    },
    handleFileRequest (option) {
      option.method = 'PUT'
      option.filename = option.file.fileName
      option.data = option.file.fields
      option.onProgress = this.onProgress
      // log.warn('handleFileRequest: ', option)
      s3utils.postToS3(option)
    },
    back () {
      this.$router.go(-1)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.publicUrl) {
            return this.$notify.error({ title: 'Error', message: `Please upload zip file.`, duration: 5000 })
          }
          this.form._id = this.$route.params.id
          this.form.zipUrl = this.publicUrl
          this.form.fileName = this.fileName
          this.form.md5 = this.md5
          let action = this.form._id === 'new' ? 'addApp': 'updateApp'
          this.$store.httpDispatch({})(action, this.form).then((res) => {
            this.$notify.success({ title: 'Successful operation', duration: 5000 })
            this.$router.push('/work/app')
          }).catch((err) => {
            console.log(err)
            this.$notify.error({ title: 'Error', message: `[${err.data.code}] ${err.data.msg}`, duration: 5000 })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getHead (url) {
      axios({
        method: 'head',
        url,
      }).then((res) => {
        const { headers } = res
        this.md5 = headers.etag ? headers.etag.slice(1, -1) : 'test'
      })
    },
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD')
    },
    async getAllCustomer () {
      this.loading = true
      let result = {}
      try {
        result = await this.$store.httpDispatch({})('getAllCustomer')
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        this.customers = data.customers
      }
      this.loading = false
    },
    async getObject () {
      this.loading = true
      let result = {}
      if (this.$route.params.id === 'new') {
        return
      }
      try {
        result = await this.$store.httpDispatch({})('getApp', {
          _id: this.$route.params.id
        })
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      const { code, data } = result.data
      if (code === 0) {
        const app = data.app
        this.form = {
          version: app.version,
          releasedAt: app.releasedAt,
          description: app.description,
          active: app.active,
          customers: app.customers || []
        }
        this.publicUrl = app.zipUrl
        this.md5 = app.md5
      }
      this.loading = false
    }
  }
}
</script>
<style lang="less">
.app-form {
  .el-form-item__label {
    text-align: left;
  }
  .el-form-item__content {
    text-align: left
  }
  .el-select .el-input {
    width: 400px;
  }
  .el-input {
    width: 200px;
  }
  .el-textarea {
    width: 500px;
  }
  .app-upload-input {
    float: left;
  }
}
</style>
